import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {User} from "../../classes/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUsers, faKey } from "@fortawesome/free-solid-svg-icons";

class Menu extends Component<{ user: User }> {
    menuItems = [
        {
            id:1,
            link: '/tickets/create',
            name: 'newtickets',
            title: 'Crear Ticket',
            icon: 'fa fa-edit' 
        },
        {
            id:1,
            link: '/tickets/mytickets/mytickets/',
            name: 'mytickets',
            title: 'Mis Tickets',
            icon: 'fa fa-list' 
        },
        {
            id:1,
            link: '/tickets',
            name: 'tickets',
            title: 'Tickets Activos',
            icon: 'fa fa-calendar-minus-o' 
        },
        {
            id:1,
            link: '/tickets',
            name: 'tickets',
            title: 'Tickets Cerrados',
            icon: 'fa fa fa-calendar-check-o' 
        },
        {
            id:1,
            link: '/tickets',
            name: 'tickets',
            title: 'Tickets Sin Asignar',
            icon: 'fa fa fa-calendar-o' 
        },
        {
            id:7,
            link: '/tickets',
            name: 'tickets',
            title: 'Todos los Tickets',
            icon: 'fa fa-ticket-alt' 
        },
        {
            id:8,
            link: '/faqs',
            name: 'faqs',
            title: 'Preguntas Frecuentes',
            icon: 'fa fa-question'  
        },
        {
            id:9,
            link: '/knowledgebases',
            name: 'knowledgebases',
            title: 'Bases de Conocimientos',
            icon: 'fa fa-head-side-virus' 
        },
        {
            id:3,
            link: '/categories',
            name: 'categories',
            title: 'Categorías',
            icon: 'fa fa-list-alt' 
        },
        {
            id:4,
            link: '/faqcategories',
            name: 'faqcategories',
            title: 'Categorías PyR',
            icon: 'fa fa-list-alt' 
        },
        {
            id:5,
            link: '/status',
            name: 'status',
            title: 'Estatus',
            icon: 'fa fa-toggle-on' 
        },
        {
            id:6,
            link: '/priorities',
            name: 'priorities',
            title: 'Prioridades',
            icon: 'fa fa-signal' 
        },
        {
            id:2,
            link: '/roles',
            name: 'roles',
            title: 'Roles de Usuarios',
            icon: 'fa fa-user-tag' 
        },
        {
            id:1,
            link: '/users',
            name: 'users',
            title: 'Usuarios',
            icon: 'fa fa-users' 
        }
    ]

    render() {
        let menu: JSX.Element[] = [];

        this.menuItems.forEach(item => {
            if (this.props.user.canView(item.name.toLowerCase())) {
                menu.push(
                    <li className="nav-item" key={item.id}>
                        <NavLink to={item.link} className="nav-link">
                        <i className={item.icon}></i> {item.title}
                        </NavLink>
                    </li>

                );
            }
        });

        return (
            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                <div className="sidebar-sticky pt-3">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink to={'/dashboard'} className="nav-link">
                                Inicio
                            </NavLink>
                        </li>
                        {menu}
                    </ul>
                </div>
            </nav>
        )
    }
}

// @ts-ignore
export default connect(state => ({user: state.user}))(Menu);