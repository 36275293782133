import React, {Component} from 'react';
import Wrapper from "../Wrapper";
//import c3 from 'c3';
//import axios from 'axios';

class Dashboard extends Component {

    componentDidMount = async () => {
        /*
        let chart = c3.generate({
            bindto: '#chart',
            data: {
                x: 'x',
                columns: [
                    ['x'],
                    ['Sales'],
                ],
                types: {
                    Sales: 'bar'
                }
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: '%Y-%m-%d'
                    }
                }
            }
        });
        

        const response = await axios.get('chart');

        const records: { date: string, sum: number }[] = response.data.data;

        chart.load({
            columns: [
                ['x', ...records.map(r => r.date)],
                ['Sales', ...records.map(r => r.sum)]
            ]
        })
        
                <h2>Daily Sales</h2>

                <div id="chart"/>
        
        */
    }


    render() {
        return (
            <Wrapper>
                <div className="container text-center justify-content-center align-self-center">
                <br /><br />
                    <h4>Bienvenidos al Sistema de Tickets</h4>
                    <span> <img src="img/logo.png" className="w-50" alt="Logo" /> </span>

                </div>                
            </Wrapper>
        )
    }

    
}

export default Dashboard;