import React, { Component, SyntheticEvent } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import Wrapper from "../Wrapper";
import { Ticket } from "../../classes/ticket";
import {User} from "../../classes/user";
import {Status} from "../../classes/status";
import {Category} from "../../classes/category";
import {Priority} from "../../classes/priority";
import FileUpload from "../components/FileUpload";


class TicketEdit extends Component<{ match: any }> {
    state = {
        id: 0,
        subject:'',
        description:'',
        file:'',
        requester_id:0,
        assignee_id:0,
        category_id:0,
        priority_id:0,
        status_id:0,
        users: [],
        status_array: [],
        categories: [],
        priorities: [],
        redirect: false
    }
    id = 0;
    subject='';
    description='';
    file='';
    requester_id=0;
    assignee_id=0;
    category_id=0;
    priority_id=0;
    status_id=0;
    
    componentDidMount = async () => {
        const response_users = await axios.get('users');
        const response_categories = await axios.get('categories');
        const response_priorities = await axios.get('priorities');
        const response_status = await axios.get('status');
        this.id = this.props.match.params.id;
        const response = await axios.get(`tickets/${this.id}`);
        const ticket: Ticket = response.data.data;
        this.setState({
            id: ticket.id,
            subject:ticket.subject,
            description:ticket.description,
            file:ticket.file,
            requester_id:ticket.requester,
            assignee_id:ticket.assignee,
            category_id:ticket.category,
            priority_id:ticket.priority,
            status_id:ticket.status,
            users: response_users.data.data,
            categories: response_categories.data.data,
            priorities: response_priorities.data.data,
            status_array: response_status.data.data

        })
    }

    changeRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    fileChanged = (file: string) => {
        this.file = file;

        this.setState({
            file: this.file
        })
    }

    submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        var self = this;
        await axios.put(`tickets/${this.id}`, {
            subject: this.subject,
            description: this.description,
            requester: this.requester_id,
            assignee: this.assignee_id,
            category: this.category_id,
            priority: this.priority_id,
            status: this.status_id
        }).then(function (response) {
            // handle success
            console.log(response);
            axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
            toast.success(<div>Registro Actualizado</div>, 
                {onClose:self.changeRedirect,autoClose: 3000} );
          }).catch(function (error) {
            // handle error
            if (error.response) {
                // Request made and server responded
                toast.error(<div>Error Actualizando Registro</div>, 
                    {autoClose: 3000} );
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
                toast.error(<div>Error Actualizando Registro</div>, 
                    {autoClose: 3000} );
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                toast.error(<div>Error Actualizando Registro</div>, 
                    {autoClose: 3000} );
              }

        });
        
    }
    
    render() {
        if (this.state.redirect) {
            return <Redirect to={'/tickets'} />;
        }
        return (
            <Wrapper>
                <h4>Editar Ticket</h4>
                <form onSubmit={this.submit}>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Asunto</label>
                                <input type="text" className="form-control" name="subject"
                                    defaultValue={this.subject = this.state.subject}
                                    onChange={e => this.setState({ subject: e.target.value })}
                                />
                            </div>
                            <div className="form-group">
                                <label>Descripción</label>
                                <textarea className="form-control"  name="description" rows={10}
                                    defaultValue={this.description = this.state.description}
                                    onChange={e => this.setState({ description: e.target.value })}
                                />
                            </div>
                            <div className="form-group">
                                <label>Archivo <a href={ this.state.file } target="_blank" title="Ver Archivo"><i className="fa fa-download"></i></a></label> 
                                <FileUpload value={this.file = this.state.file} fileChanged={this.fileChanged}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Solicitante</label>
                                <select name="requester_id" className="form-control"
                                    value={this.requester_id = this.state.requester_id}
                                    onChange={e => this.setState({ requester_id: e.target.value })}
                                >
                                    <option>Seleccione Solicitante</option>
                                    {this.state.users.map(
                                        (user: User) => {
                                            return (
                                                <option key={user.id} value={user.id}>{user.first_name} {user.last_name}({user.email})</option>
                                            )
                                        }
                                    )}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Asignado:</label>
                                <select name="assignee_id" className="form-control"
                                    value={this.assignee_id = this.state.assignee_id}
                                    onChange={e => this.setState({ assignee_id: e.target.value })}
                                >
                                    <option>Seleccione Asignado</option>
                                    {this.state.users.map(
                                        (user: User) => {
                                            return (
                                                <option key={user.id} value={user.id}>{user.first_name} {user.last_name}({user.email})</option>
                                            )
                                        }
                                    )}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Estatus:</label>
                                <select name="status_id" className="form-control"
                                    value={this.status_id = this.state.status_id}
                                    onChange={e => this.setState({ status_id: e.target.value })}
                                >
                                    <option>Seleccione Estatus</option>
                                    {this.state.status_array.map(
                                        (status: Status) => {
                                            return (
                                                <option key={status.id} value={status.id}>{status.name}</option>
                                            )
                                        }
                                    )}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Categoría:</label>
                                <select name="category_id" className="form-control"
                                    value={this.category_id = this.state.category_id}
                                    onChange={e => this.setState({ category_id: e.target.value })}
                                >
                                    <option>Seleccione Categoría</option>
                                    {this.state.categories.map(
                                        (category: Category) => {
                                            return (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            )
                                        }
                                    )}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Prioridad:</label>
                                <select name="priority_id" className="form-control"
                                    value={this.priority_id = this.state.priority_id}
                                    onChange={e => this.setState({ priority_id: e.target.value })}
                                >
                                    <option>Seleccione Prioridad</option>
                                    {this.state.priorities.map(
                                        (priority: Priority) => {
                                            return (
                                                <option key={priority.id} value={priority.id}>{priority.name}</option>
                                            )
                                        }
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <Link to="/tickets">
                        <button className="btn btn-sm btn-info">Volver</button>
                    </Link>
                    <button className="btn btn-sm btn-success">Guardar</button>
                </form>
            </Wrapper>
        );
    }
}

export default TicketEdit;