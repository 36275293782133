import React from 'react';
import './App.css';
import Dashboard from "./secure/dashboard/Dashboard";
import Users from "./secure/users/Users";
import {BrowserRouter, Route} from "react-router-dom";
import Login from "./public/Login";
import Register from "./public/Register";
import RedirectToDashboard from "./secure/RedirectToDashboard";
import UserCreate from "./secure/users/UserCreate";
import UserEdit from "./secure/users/UserEdit";
import Roles from "./secure/roles/Roles";
import RoleCreate from "./secure/roles/RoleCreate";
import RoleEdit from "./secure/roles/RoleEdit";
import Categories from "./secure/categories/Categories";
import CategoryCreate from "./secure/categories/CategoryCreate";
import CategoryEdit from "./secure/categories/CategoryEdit";
import Priorities from "./secure/priorities/Priorities";
import PriorityCreate from "./secure/priorities/PriorityCreate";
import PriorityEdit from "./secure/priorities/PriorityEdit";
import Statuses from "./secure/statuses/Statuses";
import StatusCreate from "./secure/statuses/StatusCreate";
import StatusEdit from "./secure/statuses/StatusEdit";
import Ticket from "./secure/tickets/Tickets";
import TicketCreate from "./secure/tickets/TicketCreate";
import TicketEdit from "./secure/tickets/TicketEdit";
import FaqCategories from "./secure/faqcategories/FaqCategories";
import FaqCategoryCreate from "./secure/faqcategories/FaqCategoryCreate";
import FaqCategoryEdit from "./secure/faqcategories/FaqCategoryEdit";
import Faq from "./secure/faqs/Faqs";
import FaqCreate from "./secure/faqs/FaqCreate";
import FaqEdit from "./secure/faqs/FaqEdit";
import KnowledgeBases from "./secure/knowledgebases/KnowledgeBases";
import KnowledgeBaseCreate from "./secure/knowledgebases/KnowledgeBaseCreate";
import KnowledgeBaseEdit from "./secure/knowledgebases/KnowledgeBaseEdit";
import Profile from "./secure/profile/Profile";


function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Route path={'/'} exact component={RedirectToDashboard}/>
                <Route path={'/dashboard'} exact component={Dashboard}/>
                <Route path={'/profile'} component={Profile} exact/>
                <Route path={'/login'} component={Login}/>
                <Route path={'/register'} component={Register}/>
                <Route path={'/users'} component={Users} exact/>
                <Route path={'/users/create'} component={UserCreate}/>
                <Route path={'/users/:id/edit'} component={UserEdit}/>
                <Route path={'/roles'} component={Roles} exact/>
                <Route path={'/roles/create'} component={RoleCreate}/>
                <Route path={'/roles/:id/edit'} component={RoleEdit}/>
                <Route path={'/categories'} component={Categories} exact/>
                <Route path={'/categories/create'} component={CategoryCreate}/>
                <Route path={'/categories/:id/edit'} component={CategoryEdit}/>
                <Route path={'/priorities'} component={Priorities} exact/>
                <Route path={'/priorities/create'} component={PriorityCreate}/>
                <Route path={'/priorities/:id/edit'} component={PriorityEdit}/>
                <Route path={'/status'} component={Statuses} exact/>
                <Route path={'/status/create'} component={StatusCreate}/>
                <Route path={'/status/:id/edit'} component={StatusEdit}/>
                <Route path={'/tickets'} component={Ticket} exact/>
                <Route path={'/tickets/:query/mytickets/'} component={Ticket}/>
                <Route path={'/tickets/create'} component={TicketCreate}/>
                <Route path={'/tickets/:id/edit'} component={TicketEdit}/>
                <Route path={'/faqcategories'} component={FaqCategories} exact/>
                <Route path={'/faqcategories/create'} component={FaqCategoryCreate}/>
                <Route path={'/faqcategories/:id/edit'} component={FaqCategoryEdit}/>
                <Route path={'/faqs'} component={Faq} exact/>
                <Route path={'/faqs/create'} component={FaqCreate}/>
                <Route path={'/faqs/:id/edit'} component={FaqEdit}/>
                <Route path={'/knowledgebases'} component={KnowledgeBases} exact/>
                <Route path={'/knowledgebases/create'} component={KnowledgeBaseCreate}/>
                <Route path={'/knowledgebases/:id/edit'} component={KnowledgeBaseEdit}/>
            </BrowserRouter>
        </div>
    );
}

export default App;

