import React, { Component, SyntheticEvent } from 'react';
import { Link } from "react-router-dom";
import Wrapper from "../Wrapper";
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CustomerCreate extends Component {
    state = {
        redirect: false
    }
    id = 0;
    name = '';
    

    componentDidMount = async () => {
        //const response_states = await axios.get('states');
        //const response_municipalities = await axios.get('municipalities');

        this.setState({
            //states: response_states.data.data,
            //municipalities: response_municipalities.data.data,
        })
    }
    changeRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        await axios.post('categories', {
            name: this.name
        });

        toast.success(<div>Registro Incluido</div>, 
            {onClose:this.changeRedirect,autoClose: 2500} );
    }



    render() {
        if (this.state.redirect) {
            return <Redirect to={'/categories'} />;
        }
        return (
            <Wrapper>
                <h4>Nueva Categoría</h4>
                <form onSubmit={this.submit}>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Nombre</label>
                                <input type="text" className="form-control" name="name"
                                    onChange={e => this.name = e.target.value}
                                />
                            </div>                   
                        </div>
                    </div>
                    <Link to="/priorities">
                        <button className="btn btn-sm btn-info">Volver</button>
                    </Link>
                    <button className="btn btn-sm btn-success">Guardar</button>
                </form>
            </Wrapper>
        );
    }
}

export default CustomerCreate;