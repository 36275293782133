import React, {Component} from 'react';
import axios from "axios";
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { collapseToast, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Deleter extends Component<{ id: number, endpoint: string, handleDelete: any }> {
    question = () => (
        toast.error(<div>Está seguro de eliminar registro? <br/>
            <a className="btn btn-sm btn-success"
                onClick={() => this.delete()}
            >Si</a>
            <a className="btn btn-sm btn-info"
                onClick={() => collapseToast}
            >No</a>
        </div>
            , 
            {autoClose: false})
        );

    delete = async () => {
        await axios.delete(`${this.props.endpoint}/${this.props.id}`);
        toast.success(<div>Registro Eliminado</div>, 
            {autoClose: 2000} );
        this.props.handleDelete(this.props.id);

    }

    render() {
        return (
            <a className="btn btn-sm btn-outline-secondary"
               onClick={() => this.question()}
            ><FontAwesomeIcon icon={faEraser} color="#E14F2F" /></a>
        );
    }
}

export default Deleter;