import React, { Component, SyntheticEvent } from 'react';
import './Public.css';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faKey } from "@fortawesome/free-solid-svg-icons";

class Login extends Component {
    componentDidMount = async () => {
        document.body.style.background = "black";
    }    
    
    email = '';
    password = '';
    
    state = {
        redirect: false,
        error_response:''
        
    }

    submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        var self = this;
        const response = await axios.post('login', {
            email: this.email,
            password: this.password,
            scope: 'admin'
        }).then(function (response) {
            // handle success
            localStorage.setItem('token', response.data.token);
            axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
            self.setState({
                redirect: true
            })
            console.log("response");
            console.log(response);
            
        }).catch(function (error) {
            // handle error
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                console.log(error.response.data.detail);
                self.setState({error_response: error.response.data.detail});
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }

        });

        
    }
    

    render() {
        if (this.state.redirect) {
            return <Redirect to={'/'} />;
        }

        return (
            <div>
                <div className="container text-center">
                    <span> <img src="img/logo-large-blm.png" className="w-100 logo-login" alt="Logo" /> </span><br />
                    <div className="card card-login mx-auto text-center">
                        <a className="error_response">{this.state.error_response}</a>
                        <div className="card-body">
                            <form className="form-signin" onSubmit={this.submit}>
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faUser} /></span>
                                    </div>
                                    <input type="email" id="inputEmail" className="form-control form-control-login" placeholder="Email" required
                                        onChange={e => this.email = e.target.value}
                                    />
                                </div>

                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faKey} /></span>
                                    </div>
                                    <input type="password" id="inputPassword" className="form-control form-control-login" placeholder="Password"
                                        onChange={e => this.password = e.target.value}
                                        required />
                                </div>
                                <div className="form-group">
                                    <input type="submit" name="btn" value="Entrar" className="btn btn-outline-danger float-right login_btn" />

                                </div>

                            </form>
                            
                        </div>
                        <a href="#" className="recover_password">Recuperar contraseña</a>
                    </div>

                </div>

               
                

            </div>
            


        )
    }
}

export default Login;
