import React, {Component} from 'react';
import Wrapper from "../Wrapper";
import {Link} from "react-router-dom";
import axios from 'axios';
import {FaqCategory} from "../../classes/faqcategory";
import {User} from "../../classes/user";
import Paginator from "../components/Paginator";
import Deleter from "../components/Deleter";
import {connect} from "react-redux";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Categories extends Component<{ user: User }> {
    state = {
        faqcategories: []
    }
    page = 1;
    last_page = 0;

    componentDidMount = async () => {
        const response = await axios.get(`faqcategories?page=${this.page}`);

        this.setState({
            faqcategories: response.data.data
        })

        this.last_page = response.data.meta.last_page;
    }

    handleDelete = async (id: number) => {
        this.setState({
            faqcategories: this.state.faqcategories.filter((p: FaqCategory) => p.id !== id)
        })
    }

    handlePageChange = async (page: number) => {
        this.page = page;
        await this.componentDidMount();
    }

    actions = (id: number) => {
        if (this.props.user.canEdit('faqcategories')) {
            return (
                <div className="btn-group mr-2">
                    <Link to={`/faqcategories/${id}/edit`}
                          className="btn btn-sm btn-outline-secondary">
                              <FontAwesomeIcon icon={faEdit} color="#007bff" />
                    </Link>
                    <Deleter id={id} endpoint={'faqcategories'}
                             handleDelete={this.handleDelete}/>
                </div>
            )
        }
    }

    render() {
        let addButton = null;

        if (this.props.user.canEdit('faqcategories')) {
            addButton = (
                <div
                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <Link to={'/faqcategories/create'} className="btn btn-sm btn-success">Incluir</Link>
                    </div>
                </div>
            )
        }

        return (
            <Wrapper>
                <h4>Listado de Categorías</h4>
                {addButton}
                <div className="table-responsive">
                    <table className="table table-striped table-sm">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Nombre</th>
                            <th>Acción</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.faqcategories.map(
                            (FaqCategory: FaqCategory) => {
                                return (
                                    <tr key={FaqCategory.id}>
                                        <td>{FaqCategory.id}</td>
                                        <td>{FaqCategory.name}</td>
                                        <td>{this.actions(FaqCategory.id)}</td>
                                    </tr>
                                )
                            }
                        )}
                        </tbody>
                    </table>
                </div>

                <Paginator lastPage={this.last_page} handlePageChange={this.handlePageChange}/>
            </Wrapper>
        );
    }
}

// @ts-ignore
export default connect(state => ({user: state.user}))(Categories);