import React, {Component} from 'react';
import axios from "axios";

class FileUpload extends Component<{ value: string, fileChanged: any }> {
    file = '';

    upload = async (files: FileList | null) => {
        if (files === null) return;

        const data = new FormData();
        data.append('file', files[0]);

        const response = await axios.post('upload', data);

        this.file = response.data.url;

        this.props.fileChanged(this.file);
    }

    render() {
        return (
            <div className="input-group">
                <input type="text" className="form-control" name="file"
                       value={this.file = this.props.value}
                       onChange={e => {
                           this.file = e.target.value;
                           this.props.fileChanged(this.file);
                       }}
                />
                <div className="input-group-append">
                    <label className="btn btn-primary">
                        Subir <input type="file" hidden onChange={e => this.upload(e.target.files)}/>
                    </label>
                </div>
            </div>
        );
    }
}

export default FileUpload;