import React, {Component, PropsWithRef, SyntheticEvent} from 'react';
import { Link } from "react-router-dom";
import Wrapper from "../Wrapper";
import {Role} from "../../classes/role";
import axios from "axios";
import {User} from "../../classes/user";
import {Redirect} from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class UserEdit extends Component<{ match: PropsWithRef<any> }> {
    state = {
        roles: [],
        first_name: '',
        last_name: '',
        email: '',
        password_confirm: '',
        password: '',
        role_id: 0,
        redirect: false
    }
    id = 0;
    first_name = '';
    last_name = '';
    email = '';
    password = '';
    password_confirm = '';
    role_id = 0;

    componentDidMount = async () => {
        this.id = this.props.match.params.id;
        const rolesCall = await axios.get('roles');
        const userCall = await axios.get(`users/${this.id}`);
        const user: User = userCall.data.data;
        this.setState({
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            role_id: user.role.id,
            password: user.password,
            password_confirm: user.password,
            roles: rolesCall.data.data
        })
    }
    changeRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        var self = this;
        await axios.put(`users/${this.id}`, {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            role_id: this.role_id,
            password: this.password,
            password_confirm: this.password_confirm,
        }).then(function (response) {
            // handle success
            axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
            toast.success(<div>Registro Actualizado</div>, 
                {onClose:self.changeRedirect,autoClose: 3000} );
        }).catch(function (error) {
            // handle error
            if (error.response) {
                // Request made and server responded
                toast.error(<div>Error Actualizando Registro</div>, 
                    {autoClose: 3000} );
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
                toast.error(<div>Error Actualizando Registro</div>, 
                    {autoClose: 3000} );
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                toast.error(<div>Error Actualizando Registro</div>, 
                    {autoClose: 3000} );
              }
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={'/users'}/>;
        }

        return (
            <Wrapper>
                <br/>
                <h4>Editar Usuario</h4>
                <form onSubmit={this.submit}>
                <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Nombres</label>
                                <input type="text" className="form-control" name="first_name"
                                    defaultValue={this.first_name = this.state.first_name}
                                    onChange={e => this.first_name = e.target.value}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                        <div className="form-group">
                            <label>Apellidos</label>
                            <input type="text" className="form-control" name="last_name"
                               defaultValue={this.last_name = this.state.last_name}
                               onChange={e => this.last_name = e.target.value}
                        />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Email</label>
                                <input type="text" className="form-control" name="email"
                                    defaultValue={this.email = this.state.email}
                                    onChange={e => this.email = e.target.value}
                                />
                            </div>

                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Rol</label>
                                <select name="role_id" className="form-control"
                                        value={this.role_id = this.state.role_id}
                                        onChange={e => {
                                            this.role_id = parseInt(e.target.value);
                                            this.setState({
                                                role_id: this.role_id
                                            })
                                        }}
                                >
                                    <option>Select Role</option>
                                    {this.state.roles.map(
                                        (role: Role) => {
                                            return (
                                                <option key={role.id} value={role.id}>{role.name}</option>
                                            )
                                        }
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Password</label>
                                <input type="password" className="form-control" name="password"
                                    defaultValue={this.password = this.state.password}
                                    onChange={e => this.password = e.target.value}
                                />
                            </div>
                        </div>
                        
                        <div className="col-6">
                        <div className="form-group">
                                <label>Confirmar Password</label>
                                <input type="password" className="form-control" name="password_confirm"
                                    defaultValue={this.password_confirm = this.state.password_confirm}
                                    onChange={e => this.password_confirm = e.target.value}
                                />
                            </div>
                        </div>
                    </div>
                    <Link to="/users">
                        <button className="btn btn-sm btn-info">Volver</button>
                    </Link>
                    <button className="btn btn-sm btn-success">Guardar</button>
                </form>
            </Wrapper>
        );
    }
}

export default UserEdit;