import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {User} from "../../classes/user";
import { connect } from 'react-redux';
import axios from 'axios';

class Footer extends Component<{user: User}> {
   

    render() {
        return (
            <footer className="footer  fixed-bottom bg-dark  text-center " >
                <div className="text-center text-white" >
                    © 2021 Copyright - <a className="text-white" href="https://www.blm.lat/" target="_blank">www.blm.lat</a>
                </div>
            </footer>
        )
    }
}

// @ts-ignore
export default connect(state => ({user: state.user}))(Footer);
