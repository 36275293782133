import React, {Component, Dispatch, SyntheticEvent} from 'react';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import {User} from "../../classes/user";
import {connect} from "react-redux";
import setUser from "../../redux/actions/setUserAction";
import Wrapper from "../Wrapper";


class Profile extends Component<any> {
    state = {
        first_name: '',
        last_name: '',
        email: '',
        redirect: false
    }
    first_name = '';
    last_name = '';
    email = '';
    password = '';
    password_confirm = '';

    changeRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    updateInfo = async (e: SyntheticEvent) => {
        e.preventDefault();
        var self = this;
        const response = await axios.put('users/info', {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
        }).then(function (response) {
            // handle success
            const user: User = response.data;
            self.props.setUser(new User(
                user.id,
                user.first_name,
                user.last_name,
                user.email,
                user.role,
                user.permissions,
            ));
            toast.success(<div>Cuenta Actualizada</div>, 
                {onClose:self.changeRedirect,autoClose: 3000} );
          }).catch(function (error) {
            // handle error
            if (error.response) {
                // Request made and server responded
                toast.error(<div>Error Actualizando Cuenta</div>, 
                    {autoClose: 3000} );
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
                toast.error(<div>Error Actualizando Cuenta</div>, 
                    {autoClose: 3000} );
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                toast.error(<div>Error Actualizando Cuenta</div>, 
                    {autoClose: 3000} );
              }

        });

        
    }

    updatePassword = async (e: SyntheticEvent) => {
        e.preventDefault();
        var self = this;
        await axios.put('users/password', {
            password: this.password,
            password_confirm: this.password_confirm
        }).then(function (response) {
            // handle success
            const user: User = response.data;
            self.props.setUser(new User(
                user.id,
                user.first_name,
                user.last_name,
                user.email,
                user.role,
                user.permissions,
            ));
            toast.success(<div>Password Actualizado</div>, 
                {onClose:self.changeRedirect,autoClose: 3000} );
          }).catch(function (error) {
            // handle error
            if (error.response) {
                // Request made and server responded
                toast.error(<div>Error Actualizando Password</div>, 
                    {autoClose: 3000} );
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
                toast.error(<div>Error Actualizando Password</div>, 
                    {autoClose: 3000} );
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                toast.error(<div>Error Actualizando Password</div>, 
                    {autoClose: 3000} );
              }

        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={'/dashboard'} />;
        }
        return (
            <Wrapper>

                <h2>Cuenta</h2>
                <hr/>
                <form onSubmit={this.updateInfo}>
                    <div className="row">
                        <div className="col-4">
                        <div className="form-group">
                            <label>Nombres</label>
                            <input type="text" className="form-control" name="first_name"
                                defaultValue={this.first_name = this.props.user.first_name}
                                onChange={e => this.first_name = e.target.value}
                            />
                        </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label>Apellidos</label>
                                    <input type="text" className="form-control" name="last_name"
                                        defaultValue={this.last_name = this.props.user.last_name}
                                        onChange={e => this.last_name = e.target.value}
                                    />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label>Email</label>
                                <input type="text" className="form-control" name="email"
                                    defaultValue={this.email = this.props.user.email}
                                    onChange={e => this.email = e.target.value}
                                />
                            </div>
                        </div>
                    </div>

                    <button className="btn btn-sm btn-success">Guardar</button>
                </form>

                <h2 className="mt-4">Cambiar Password</h2>
                <hr/>
                <form onSubmit={this.updatePassword}>
                <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label>Password</label>
                                <input type="password" className="form-control" name="password"
                                    onChange={e => this.password = e.target.value}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label>Confirmar Password</label>
                                <input type="password" className="form-control" name="password_confirm"
                                    onChange={e => this.password_confirm = e.target.value}
                                />
                            </div>
                        </div>
                    </div>
                    
                    <button className="btn btn-sm btn-success">Guardar</button>
                </form>
            </Wrapper>
        );
    }
}

// @ts-ignore
export default connect(state => ({user: state.user}), dispatch => ({setUser: user => dispatch(setUser(user))}))(Profile);