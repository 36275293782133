import React, { Component, SyntheticEvent } from 'react';
import { Link } from "react-router-dom";
import Wrapper from "../Wrapper";
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { KnowledgeBase } from "../../classes/knowledgebase";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {User} from "../../classes/user";
import {Ticket} from "../../classes/ticket";


class KnowledgeBaseEdit extends Component<{ match: any }> {
    state = {
        id: 0,
        subject:'',
        description:'',
        created_id:0,
        ticket_id:0,
        users: [],
        tickets: [],
        redirect: false
    }
    id = 0;
    subject='';
    description='';
    created_id=0;
    ticket_id=0;
    
    
    componentDidMount = async () => {
        const response_users = await axios.get('users');
        const response_tickets = await axios.get('tickets');
        this.id = this.props.match.params.id;
        const response = await axios.get(`knowledgebases/${this.id}`);
        const knowledgebase: KnowledgeBase = response.data.data;
        this.setState({
            id: knowledgebase.id,
            subject:knowledgebase.subject,
            description:knowledgebase.description,
            created_id:knowledgebase.created,
            ticket_id:knowledgebase.ticket,
            users: response_users.data.data,
            tickets: response_tickets.data.data
        })
    }

    changeRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        await axios.put(`knowledgebases/${this.id}`, {
            subject: this.subject,
            description: this.description,
            created: this.created_id,
            ticket: this.ticket_id
        });
        toast.success(<div>Registro Actualizado</div>, 
            {onClose:this.changeRedirect,autoClose: 2500} );
    }
    
    render() {
        if (this.state.redirect) {
            return <Redirect to={'/knowledgebases'} />;
        }
        return (
            <Wrapper>
                <h4>Editar Base de Conocimiento</h4>
                <form onSubmit={this.submit}>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Asunto:</label>
                                <input type="text" className="form-control" name="question"
                                    defaultValue={this.subject = this.state.subject}
                                    onChange={e => this.setState({ subject: e.target.value })}
                                />
                            </div>
                            <div className="form-group">
                                <label>Descripción</label>
                                <textarea className="form-control"  name="description" rows={10}
                                    defaultValue={this.description = this.state.description}
                                    onChange={e => this.setState({ description: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Creado por:</label>
                                <select name="created_id" className="form-control"
                                    value={this.created_id = this.state.created_id}
                                    onChange={e => this.setState({ created_id: e.target.value })}
                                >
                                    <option>Seleccione </option>
                                    {this.state.users.map(
                                        (user: User) => {
                                            return (
                                                <option key={user.id} value={user.id}>{user.first_name} {user.last_name}({user.email})</option>
                                            )
                                        }
                                    )}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Ticket:</label>
                                <select name="ticket_id" className="form-control"
                                    value={this.ticket_id = this.state.ticket_id}
                                    onChange={e => this.setState({ ticket_id: e.target.value })}
                                >
                                    <option>Seleccione </option>
                                    {this.state.tickets.map(
                                        (ticket: Ticket) => {
                                            return (
                                                <option key={ticket.id} value={ticket.id}>{ticket.id}-{ticket.subject} </option>
                                            )
                                        }
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <Link to="/knowledgebases">
                        <button className="btn btn-sm btn-info">Volver</button>
                    </Link>
                    <button className="btn btn-sm btn-success">Guardar</button>
                </form>
            </Wrapper>
        );
    }
}

export default KnowledgeBaseEdit;