import React, {Component, SyntheticEvent} from 'react';
import { Link } from "react-router-dom";
import Wrapper from "../Wrapper";
import axios from 'axios';
import {Role} from "../../classes/role";
import {Redirect} from 'react-router-dom';
import { toast } from 'react-toastify';


class UserCreate extends Component {
    state = {
        roles: [],
        redirect: false
    }
    first_name = '';
    last_name = '';
    email = '';
    role_id = 0;
    password = '';
    password_confirm = '';

    componentDidMount = async () => {
        const response = await axios.get('roles');

        this.setState({
            roles: response.data.data
        })
    }

    changeRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        var self = this;
        await axios.post('users', {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            role: this.role_id,
            password: this.password,
            password_confirm: this.password_confirm
        }).then(function (response) {
            // handle success
            console.log(response);
            axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
            toast.success(<div>Registro Incluido</div>, 
                {onClose:self.changeRedirect,autoClose: 3000} );
          }).catch(function (error) {
            // handle error
            if (error.response) {
                // Request made and server responded
                toast.error(<div>Error Incluyendo Registro</div>, 
                    {autoClose: 3000} );
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
                toast.error(<div>Error Incluyendo Registro</div>, 
                    {autoClose: 3000} );
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                toast.error(<div>Error Incluyendo Registro</div>, 
                    {autoClose: 3000} );
              }

        });

        
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={'/users'}/>;
        }

        return (
            <Wrapper>
                <br/>
                <h4>Nuevo Usuario</h4>
                <form onSubmit={this.submit}>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Nombres</label>
                                <input type="text" className="form-control" name="first_name"
                                    onChange={e => this.first_name = e.target.value}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                        <div className="form-group">
                            <label>Apellidos</label>
                            <input type="text" className="form-control" name="last_name"
                                onChange={e => this.last_name = e.target.value}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Email</label>
                                <input type="text" className="form-control" name="email"
                                    onChange={e => this.email = e.target.value}
                                />
                            </div>

                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Rol</label>
                                <select name="role_id" className="form-control"
                                        onChange={e => this.role_id = parseInt(e.target.value)}
                                >
                                    <option>Select Role</option>
                                    {this.state.roles.map(
                                        (role: Role) => {
                                            return (
                                                <option key={role.id} value={role.id}>{role.name}</option>
                                            )
                                        }
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Password</label>
                                <input type="password" className="form-control" name="password"
                                    onChange={e => this.password = e.target.value}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                        <div className="form-group">
                                <label>Confirmar Password</label>
                                <input type="password" className="form-control" name="password_confirm"
                                    onChange={e => this.password_confirm = e.target.value}
                                />
                            </div>
                        </div>
                    </div>
                    <Link to="/users">
                        <button className="btn btn-sm btn-info">Volver</button>
                    </Link>
                    <button className="btn btn-sm btn-success">Guardar</button>
                </form>
            </Wrapper>
        );
    }
}

export default UserCreate;