import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import configureStore from "./redux/configureStore";
import {Provider} from "react-redux";

//Local
axios.defaults.baseURL = 'http://localhost:8010/api/';
//Desarrollo
//axios.defaults.baseURL = 'http://ticketsback.sodehg.com/api/';
//Produccion
//axios.defaults.baseURL = 'http://ticketsback.soporteblm.lat/api/';



axios.defaults.withCredentials = true;
axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

const store = configureStore();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
