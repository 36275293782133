import React, { Component, SyntheticEvent } from 'react';
import { Link } from "react-router-dom";
import Wrapper from "../Wrapper";
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {User} from "../../classes/user";
import {Ticket} from "../../classes/ticket";


class KnowledgeBaseCreate extends Component {
    state = {
        users: [],
        tickets: [],
        redirect: false
    }
    id = 0;
    subject='';
    description='';
    created_id=0;
    ticket_id=0;
    
    componentDidMount = async () => {
        const response_users = await axios.get('users');
        const response_tickets = await axios.get('tickets');
        this.setState({
            users: response_users.data.data,
            tickets: response_tickets.data.data
        })
    }
    changeRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        await axios.post('knowledgebases', {
            subject: this.subject,
            description: this.description,
            created: this.created_id,
            ticket: this.ticket_id,
        });
        toast.success(<div>Registro Incluido</div>, 
            {onClose:this.changeRedirect,autoClose: 2500} );
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={'/knowledgebases'} />;
        }
        return (
            <Wrapper>
                <h4>Nueva Base de Conocimiento</h4>
                <form onSubmit={this.submit}>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Asunto</label>
                                <input type="text" className="form-control" name="subject"
                                    onChange={e => this.subject = e.target.value}
                                />
                            </div>
                            <div className="form-group">
                                <label>Descripción</label>
                                <textarea className="form-control"  name="description" rows={10}
                                    onChange={e => this.description = e.target.value}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Creado por:</label>
                                <select name="created_id" className="form-control"
                                    onChange={e => this.created_id = parseInt(e.target.value)}
                                >
                                    <option>Seleccione </option>
                                    {this.state.users.map(
                                        (user: User) => {
                                            return (
                                                <option key={user.id} value={user.id}>{user.first_name} {user.last_name}({user.email})</option>
                                            )
                                        }
                                    )}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Ticket:</label>
                                <select name="ticket_id" className="form-control"
                                    onChange={e => this.ticket_id = parseInt(e.target.value)}
                                >
                                    <option>Seleccione Ticket</option>
                                    {this.state.tickets.map(
                                        (ticket: Ticket) => {
                                            return (
                                                <option key={ticket.id} value={ticket.id}>{ticket.id} {ticket.subject}</option>
                                            )
                                        }
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <Link to="/knowledgebases">
                        <button className="btn btn-sm btn-info">Volver</button>
                    </Link>
                    <button className="btn btn-sm btn-success">Guardar</button>
                </form>
            </Wrapper>
        );
    }
}

export default KnowledgeBaseCreate;