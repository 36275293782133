import React, { Component, SyntheticEvent } from 'react';
import { Link } from "react-router-dom";
import Wrapper from "../Wrapper";
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { Category } from "../../classes/category";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class CustomerEdit extends Component<{ match: any }> {
    state = {
        name: '',
        redirect: false
    }
    id = 0;
    name = '';
    
    componentDidMount = async () => {
        this.id = this.props.match.params.id;
        const response = await axios.get(`categories/${this.id}`);
        const customer: Category = response.data.data;
        this.setState({
            name: customer.name
        })
    }

    changeRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        await axios.put(`categories/${this.id}`, {
            name: this.state.name
        });
        toast.success(<div>Registro Actualizado</div>, 
            {onClose:this.changeRedirect,autoClose: 2500} );
    }
    
    render() {
        if (this.state.redirect) {
            return <Redirect to={'/categories'} />;
        }
        return (
            <Wrapper>
                <h4>Editar Categoría</h4>
                <form onSubmit={this.submit}>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Nombre</label>
                                <input type="text" className="form-control" name="name"
                                    defaultValue={this.state.name}
                                    onChange={e => this.setState({ name: e.target.value })}
                                />
                            </div>    
                        </div>
                    </div>
                    <Link to="/priorities">
                        <button className="btn btn-sm btn-info">Volver</button>
                    </Link>
                    <button className="btn btn-sm btn-success">Guardar</button>
                </form>
            </Wrapper>
        );
    }
}

export default CustomerEdit;