import React, {Component} from 'react';
import Wrapper from "../Wrapper";
import {Link} from "react-router-dom";
import axios from 'axios';
import {Ticket} from "../../classes/ticket";
import Paginator from "../components/Paginator";
import Deleter from "../components/Deleter";
import {connect} from "react-redux";
import {User} from "../../classes/user";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Tickets extends Component<{ user: User, match: any}> {
    state = {
        tickets: []
    }
    page = 1;
    last_page = 0;
    query='';
    
    componentDidMount = async () => {
        
        var self = this;
        var url = `tickets?page=${this.page}`
        self.query = this.props.match.params.query
        if (this.props.match.params.query){
            var user = this.props.user
            if((self.query=='mytickets')&&(user.role.name=='User')){
                var url = `tickets?page=${this.page}&usuario=${user.id}`
            }
        };
        
        const response = await axios.get(url).then(function (response) {
            // handle success
            self.setState({
                tickets: response.data.data
            })
            self.last_page = response.data.meta.last_page;
            console.log(response);
            console.log(self.query);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
        
    }

    handleDelete = async (id: number) => {
        this.setState({
            tickets: this.state.tickets.filter((p: Ticket) => p.id !== id)
        })
    }

    handlePageChange = async (page: number) => {
        this.page = page;
        await this.componentDidMount();
    }

    actions = (id: number) => {
        if (this.props.user.canEdit('tickets')) {
            return (
                <div className="btn-group mr-2">
                    <Link to={`/tickets/${id}/edit`}
                          className="btn btn-sm btn-outline-secondary">
                              <FontAwesomeIcon icon={faEdit} color="#007bff" />
                    </Link>
                    <Deleter id={id} endpoint={'tickets'}
                             handleDelete={this.handleDelete}/>
                </div>
            )
        }
    }

    render() {
        let addButton = null;

        if (this.props.user.canEdit('tickets')) {
            addButton = (
                <div
                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <Link to={'/tickets/create'} className="btn btn-sm btn-success">Incluir</Link>
                    </div>
                </div>
            )
        }

        return (
            <Wrapper>
                <h4>Tickets</h4>
                {addButton}
                <div className="table-responsive">
                    <table className="table table-striped table-sm">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Asunto</th>
                            <th>Descripción</th>
                            <th>Acción</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.tickets.map(
                            (Ticket: Ticket) => {
                                return (
                                    <tr key={Ticket.id}>
                                        <td>{Ticket.id}</td>
                                        <td>{Ticket.subject}</td>
                                        <td>{Ticket.description}</td>
                                        <td>{this.actions(Ticket.id)}</td>
                                    </tr>
                                )
                            }
                        )}
                        </tbody>
                    </table>
                </div>

                <Paginator lastPage={this.last_page} handlePageChange={this.handlePageChange}/>
            </Wrapper>
        );
    }
}

// @ts-ignore
export default connect(state => ({user: state.user}))(Tickets);