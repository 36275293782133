import React, { Component, SyntheticEvent } from 'react';
import { Link } from "react-router-dom";
import Wrapper from "../Wrapper";
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {User} from "../../classes/user";
import {FaqCategory} from "../../classes/faqcategory";


class StatusCreate extends Component {
    state = {
        users: [],
        faqcategories: [],
        redirect: false
    }
    id = 0;
    question='';
    answer='';
    created_id=0;
    faqcategory_id=0;
    
    
    componentDidMount = async () => {
        const response_users = await axios.get('users');
        const response_faqcategories = await axios.get('faqcategories');
        this.setState({
            users: response_users.data.data,
            faqcategories: response_faqcategories.data.data
        })
    }
    changeRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        await axios.post('faqs', {
            question: this.question,
            answer: this.answer,
            faqcategory: this.faqcategory_id,
            created: this.created_id
        });
        toast.success(<div>Registro Incluido</div>, 
            {onClose:this.changeRedirect,autoClose: 2500} );
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={'/faqs'} />;
        }
        return (
            <Wrapper>
                <h4>Nueva Pregunta Frecuente</h4>
                <form onSubmit={this.submit}>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Pregunta</label>
                                <input type="text" className="form-control" name="question"
                                    onChange={e => this.question = e.target.value}
                                />
                            </div>
                            <div className="form-group">
                                <label>Respuesta</label>
                                <textarea className="form-control"  name="answer" rows={10}
                                    onChange={e => this.answer = e.target.value}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Creado por:</label>
                                <select name="created_id" className="form-control"
                                    onChange={e => this.created_id = parseInt(e.target.value)}
                                >
                                    <option>Seleccione </option>
                                    {this.state.users.map(
                                        (user: User) => {
                                            return (
                                                <option key={user.id} value={user.id}>{user.first_name} {user.last_name}({user.email})</option>
                                            )
                                        }
                                    )}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Categoría:</label>
                                <select name="faqcategory_id" className="form-control"
                                    onChange={e => this.faqcategory_id = parseInt(e.target.value)}
                                >
                                    <option>Seleccione </option>
                                    {this.state.faqcategories.map(
                                        (faqcategory: FaqCategory) => {
                                            return (
                                                <option key={faqcategory.id} value={faqcategory.id}>{faqcategory.name}</option>
                                            )
                                        }
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <Link to="/faqs">
                        <button className="btn btn-sm btn-info">Volver</button>
                    </Link>
                    <button className="btn btn-sm btn-success">Guardar</button>
                </form>
            </Wrapper>
        );
    }
}

export default StatusCreate;